import { Component, OnInit, Input } from '@angular/core';
import { Operator } from 'src/app/models/operator';

@Component({
  selector: 'app-card-staff',
  templateUrl: './card-staff.component.html',
  styleUrls: ['./card-staff.component.scss']
})
export class CardStaffComponent implements OnInit {
  @Input() staff: Operator;

  constructor() { }

  ngOnInit() {
  }

}
