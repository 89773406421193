import { BaseEntity } from './base-entity';
import { Operator } from './operator';

export class ProgramMember extends BaseEntity<ProgramMember> {
  role: string;
  operator?: Operator;

  constructor(programMember?) {
    super(programMember);
  }

  parse(e: any): ProgramMember {
    const programMember = new ProgramMember(e);
    return programMember;
  }

  populate(programMember) {
    this.role = programMember.role;
    if (programMember.operator) { this.operator = new Operator(programMember.operator); }
  }
}
