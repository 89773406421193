import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
// Layout
import { PublicLayoutComponent } from '../core/components/layouts/public/public.component';
import { WebViewLayoutComponent } from './components/layouts/web-view-layout/web-view-layout.component';
import { SharedModule } from '../shared/shared.module';
import { LoaderModule } from '../shared/components/loader/loader.module';

// Services
import { Ng2IzitoastService } from 'ng2-izitoast';
import { RouteService } from './services/route/route.service';

@NgModule({
  declarations: [
    PublicLayoutComponent,
    WebViewLayoutComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    LoaderModule
  ],
  exports: [
    LoaderModule
  ],
  providers: [
    Ng2IzitoastService,
    RouteService
  ]
})
export class CoreModule { }
