import { Component, OnInit, Input } from '@angular/core';
import { SocialMedia } from 'src/app/models/social-media';
import { isNullOrEmptyOrUndefined } from 'src/app/helper/utils';
import { GoogleAnalyticsService } from 'src/app/core/services/google-analytics/google-analytics.service';
import { CatalogGoogleAnalyticsCategories, CatalogGoogleAnalyticsActions } from 'src/app/helper/catalog';
import { Podcast } from 'src/app/models/podcast';
import { BaseResponse } from 'src/app/models/base-response';
import { Router } from '@angular/router';

@Component({
  selector: 'app-social-media',
  templateUrl: './social-media.component.html',
  styleUrls: ['./social-media.component.scss']
})
export class SocialMediaComponent implements OnInit {
  @Input() socialMedia: SocialMedia;
  @Input() slug: string;
  @Input() showWhatsapp = true;
  @Input() playlistName = 'Playlist';
  @Input() radioPodcasts: BaseResponse<Podcast>;
  @Input() podcastName = 'Podcast';
  @Input() soundcloudName = 'Soundcloud';
  @Input() title: string;

  constructor(
    private router: Router,
    private googleAnalyticsService: GoogleAnalyticsService
  ) { }

  ngOnInit() {
  }

  checkVisibility(item) {
    return (!isNullOrEmptyOrUndefined(item)) ? true : false;
  }

  emitGoogleAnalyticsEvent() {
    this.googleAnalyticsService.emitEvent(CatalogGoogleAnalyticsCategories.whatsapp, CatalogGoogleAnalyticsActions.click, this.slug);
  }

  goToSpotifyList() {
    this.router.navigate(['spotify', 'list']);
  }

}
