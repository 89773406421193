import { BaseEntity } from './base-entity';
import { SocialMedia } from './social-media';
import { DefaultAvatar } from '../helper/config';

export class Operator extends BaseEntity<Operator> {
  id: number;
  slug: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  avatar: string;
  password: string;
  profiles: string[];
  description: string;
  sex: 'M' | 'F';
  socialMedia?: SocialMedia;

  constructor(admin?) {
    super(admin);
  }

  parse(e: any): Operator {
    const operator = new Operator(e);
    return operator;
  }

  populate(operator) {
    this.id = operator.id;
    this.slug = operator.slug;
    this.firstName = operator.firstName;
    this.lastName = operator.lastName;
    this.fullName = `${operator.firstName} ${operator.lastName}`;
    this.email = operator.email;
    this.avatar = (operator.avatar) ? operator.avatar : DefaultAvatar;
    this.password = operator.password;
    this.profiles = (operator.profiles) ? operator.profiles : [];
    this.description = operator.description;
    this.sex = operator.sex;
    if (operator.socialMedia) { this.socialMedia = new SocialMedia(operator.socialMedia); }
  }

  getClassToCropImage() {
    const image = new Image();
    image.src = this.avatar;
    return (image.height > image.width) ? 'portrait' : 'landscape';
  }
}
