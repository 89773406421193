import { ApiService } from '../api/api.service';
import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { EndpointsConstants } from 'src/app/helper/endpoints';
import { Observable } from 'rxjs';
import { BaseResponse } from 'src/app/models/base-response';
import { Program } from 'src/app/models/program';
import { generateParamsForRoutes } from 'src/app/helper/utils';

@Injectable({
    providedIn: 'root'
})
export class GridService extends ApiService {

    constructor(
        httpService: HttpService
    ) {
        super(httpService);
        this.baseUrl = EndpointsConstants.grid.base;
    }

    getNow(page: number = 0, pageSize: number = 0, filters: any = []): Observable<BaseResponse<Program>> {
        const url = this.baseUrl + EndpointsConstants.grid.now;
        const params = generateParamsForRoutes(filters, page, pageSize);
        return this.httpService.get<Program>(url, params, true);
    }
}
