import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { getLocalData, setLocalData } from 'src/app/helper/storage';
import { Program } from 'src/app/models/program';
import { isNullOrEmptyOrUndefined } from 'src/app/helper/utils';
import { FavouriteService } from 'src/app/core/services/favourite/favourite.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-button-options',
  templateUrl: './button-options.component.html',
  styleUrls: ['./button-options.component.scss']
})
export class ButtonOptionsComponent implements OnInit, OnDestroy {
  isFavourite = false;
  favouriteSubscription: Subscription;

  @Input() program: Program;

  @Output() togglePlayer = new EventEmitter();

  constructor(private favouriteService: FavouriteService) { }

  ngOnInit() {
    this.subscribeToFavourite();
  }

  subscribeToFavourite() {
    this.favouriteSubscription = this.favouriteService.changeFavourite$.subscribe( _ => {
      this.checkFavourite();
    });
  }

  checkFavourite() {
    const actualFavourites = getLocalData('m90-web-favourites');
    if (!isNullOrEmptyOrUndefined(actualFavourites)) {
      this.isFavourite = actualFavourites.includes(this.program.id);
    }
  }

  addFavourites() {
    const actualFavourites = getLocalData('m90-web-favourites');
    const favourites = (isNullOrEmptyOrUndefined(actualFavourites)) ? [this.program.id] : [...actualFavourites, this.program.id];
    setLocalData('m90-web-favourites', favourites);
    this.isFavourite = true;
    this.favouriteService.emitChangeFavourite();
  }

  removeFavourite() {
    const actualFavourites = getLocalData('m90-web-favourites');
    const favourites = actualFavourites.filter(item => item !== this.program.id);
    setLocalData('m90-web-favourites', favourites);
    this.isFavourite = false;
    this.favouriteService.emitChangeFavourite();
  }

  emitTogglePlayer() {
    this.togglePlayer.emit();
  }

  ngOnDestroy() {
    if (this.favouriteSubscription) { this.favouriteSubscription.unsubscribe(); }
  }
}
