import { Routes } from '@angular/router';
import { CommercialComponent } from '../components/commercial/commercial.component';

// Public routes
export const PUBLIC_ROUTES: Routes = [
  {
    path: '',
    data: {
      background: 'green'
    },
    loadChildren: () => import('../../modules/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'error',
    data: {
      background: 'green'
    },
    loadChildren: () => import('../../modules/errors/errors.module').then(m => m.ErrorsModule)
  },
  {
    path: 'programa',
    data: {
      background: 'orange'
    },
    loadChildren: () => import('../../modules/program/program.module').then(m => m.ProgramModule)
  },
  {
    path: 'staff',
    data: {
      background: 'green'
    },
    loadChildren: () => import('../../modules/staff/staff.module').then(m => m.StaffModule)
  },
  {
    path: 'grilla',
    data: {
      background: 'green',
      goBackClass: 'gridGoBack'
    },
    loadChildren: () => import('../../modules/grid/grid.module').then(m => m.GridModule)
  },
  {
    path: 'noticias',
    data: {
      background: 'green'
    },
    loadChildren: () => import('../../modules/news/news.module').then(m => m.NewsModule)
  },
  {
    path: 'spotify',
    data: {
      background: 'green',
      goBackClass: 'spotifyGoBack'
    },
    loadChildren: () => import('../../modules/spotify/spotify.module').then(m => m.SpotifyModule)
  },
  {
    path: 'comercial',
    data: {
      background: 'green'
    },
    component: CommercialComponent
  }
];
