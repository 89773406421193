import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { RouteService } from 'src/app/core/services/route/route.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-go-back',
  templateUrl: './go-back.component.html',
  styleUrls: ['./go-back.component.scss']
})
export class GoBackComponent implements OnInit {

  @Input() goBackClass: string;

  constructor(
    private location: Location,
    private router: Router,
    private routeService: RouteService
  ) { }

  ngOnInit() {
  }

  goBack() {
    const previousUrl = this.routeService.getPreviousUrl();
    previousUrl === '/' ? this.router.navigate(['/']) : this.location.back();
  }
}
