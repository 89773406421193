import { Pipe, PipeTransform } from '../../../../node_modules/@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[] = [], field: string, value: string): any[] {
    return items.filter( item => item[field] === value);
  }
}
