export enum CatalogResponsiveBreakpoints {
    desktop = 1200,
    laptop = 991,
    tablet = 768,
    smart = 480,
    pageYOffsetMenu = 246
}

export enum CatalogSettings {
    facebook = 'FACEBOOK',
    instagram = 'INSTAGRAM',
    whatsapp = 'WHATSAPP',
    youtube = 'YOUTUBE',
    twitter = 'TWITTER',
    podcast = 'PODCAST',
    playlist = 'PLAYLIST',
    description = 'DESCRIPTION',
    streaming = 'STREAMING'
}

export const CatalogCommunicationSettings = [
    'CONTACT_MAIL',
    'COMMERCIAL_MAIL',
    'COMMERCIAL_PHONE',
    'FACEBOOK',
    'INSTAGRAM',
    'TWITTER'
];

export enum CatalogApiScopes {
    socialMedia = 'socialMedia',
    program = 'program',
    programs = 'programs',
    operator = 'operator',
    programMembers = 'programMembers'
}

export const DaysOfWeek = ['Lun.', 'Mar.', 'Mie.', 'Jue.', 'Vie.', 'Sab.', 'Dom.'];

export enum CatalogGoogleAnalyticsCategories {
    program = 'PROGRAM',
    staff = 'STAFF',
    news = 'NEWS',
    whatsapp = 'WHATSAPP',
    radioAudience = 'RADIO_AUDIENCE',
    heartbeat = 'HEARTBEAT'
}

export enum CatalogGoogleAnalyticsActions {
    listen = 'LISTEN',
    click = 'CLICK',
    view = 'VIEW',
    hearbeatTime = '10s'
}

