import { Component, OnInit, Input } from '@angular/core';
import { NguCarouselConfig } from '@ngu/carousel';
import { ProgramMember } from 'src/app/models/program-member';

@Component({
  selector: 'app-list-program',
  templateUrl: './list-program.component.html',
  styleUrls: ['./list-program.component.scss']
})
export class ListProgramComponent implements OnInit {
  carouselConfig: NguCarouselConfig = {
    grid: { xs: 2, sm: 2, md: 2, lg: 2, all: 0 },
    interval: { timing: 4000, initialDelay: 1000 },
    loop: false,
    touch: true,
    velocity: 0,
    point: {
      visible: true,
      hideOnSingleSlide: true
    }
  };

  @Input() programs: ProgramMember[] = [];

  constructor() { }

  ngOnInit() {
  }
}
