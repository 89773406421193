import { Component, OnInit, OnDestroy } from '@angular/core';
import { Operator } from 'src/app/models/operator';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { isNullOrEmptyOrUndefined } from 'src/app/helper/utils';
import { Program } from 'src/app/models/program';
import { Subscription } from 'rxjs';
import { OperatorService } from 'src/app/core/services/operator/operator.service';
import { ProgramService } from 'src/app/core/services/program/program.service';
import { PushNotificationService } from 'ngx-push-notifications';
import { SettingsService } from 'src/app/core/services/settings/settings.service';
import { CatalogSettings, CatalogGoogleAnalyticsCategories, CatalogGoogleAnalyticsActions } from 'src/app/helper/catalog';
import { GoogleAnalyticsService } from 'src/app/core/services/google-analytics/google-analytics.service';
import { PlayerService } from 'src/app/core/services/player/player.service';
import { showHideAnimation } from 'src/app/shared/animations/animations';

@Component({
  selector: 'app-public-layout',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.scss'],
  animations: [
    showHideAnimation
  ]
})
export class PublicLayoutComponent implements OnInit, OnDestroy {
  showBackButton = false;
  backgroundColor = 'green';
  defaultMobileInfo = true;
  staff: Operator;
  staffSubscription: Subscription;
  program: Program;
  programSubscription: Subscription;
  whatsapp: string;
  goBackClass: string;
  playClicked = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private operatorService: OperatorService,
    private programService: ProgramService,
    private settingsService: SettingsService,
    private pushNotificationService: PushNotificationService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private playerService: PlayerService
  ) { }

  ngOnInit() {
    this.pushNotificationService.requestPermission().subscribe();
    this.initializeScreen();
    this.subscribeToRouterEvents();
    this.subscribeToCurrentOperator();
    this.subscribeToViewProgram();
    this.getRadioWhatsapp();
  }

  initializeScreen() {
    this.showBackButton = (this.router.url !== '/') ? true : false;
    if (!isNullOrEmptyOrUndefined(this.route.snapshot.firstChild.data.background)) {
      this.backgroundColor = this.route.snapshot.firstChild.data.background;
    }
    this.goBackClass = !isNullOrEmptyOrUndefined(this.route.snapshot.firstChild.data.goBackClass) ? this.route.snapshot.firstChild.data.goBackClass : '';
  }

  subscribeToRouterEvents() {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.initializeScreen();
        const isStaff = val.urlAfterRedirects.indexOf('staff') > -1;
        const isProgram = val.urlAfterRedirects.indexOf('program') > -1;
        if (!isStaff) {
          this.staff = undefined;
        }
        if (!isProgram) {
          this.program = undefined;
        }
        this.defaultMobileInfo = !isStaff && !isProgram ? true : false;
      }
    });
  }

  subscribeToCurrentOperator() {
    this.staffSubscription = this.operatorService.currentOperator$.subscribe( operator => {
      this.staff = operator;
    });
  }

  subscribeToViewProgram() {
    this.programSubscription = this.programService.viewProgram$.subscribe( program => {
      this.program = program;
    });
  }

  getRadioWhatsapp() {
    const filters = {
      code: CatalogSettings.whatsapp
    };
    this.settingsService.get(null, null, filters).subscribe( response => {
      if (response.data['rows'].length) {
        this.whatsapp = response.data['rows'][0].value;
      }
    });
  }

  emitGoogleAnalyticsEvent() {
    this.googleAnalyticsService.emitEvent(CatalogGoogleAnalyticsCategories.whatsapp, CatalogGoogleAnalyticsActions.click, 'M90');
  }

  changeStatusPlayer() {
    this.playClicked = true;
    this.playerService.emitPlayerStatus(true);
  }

  ngOnDestroy() {
    if (this.staffSubscription) { this.staffSubscription.unsubscribe(); }
    if (this.programSubscription) { this.programSubscription.unsubscribe(); }
  }
}
