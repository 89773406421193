// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api: {
    baseUrl: 'https://api.m90radio.com/v1'
  },
  socket: {
    baseUrl: 'https://stg.socket.m90radio.com/',
    key: 'mks32SbP10Vze'
  },
  firebaseConfig: {
    apiKey: 'AIzaSyBegTSoN5nMY-xxzqr3U4EP9jAPiWY3H0k',
    authDomain: 'm90-radio.firebaseapp.com',
    databaseURL: 'https://m90-radio.firebaseio.com',
    projectId: 'm90-radio',
    storageBucket: 'm90-radio.appspot.com',
    messagingSenderId: '353303319319',
    appId: '1:353303319319:web:508ae2d6da430e121d9d52',
    measurementId: 'G-L4PFYNRWDY'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
