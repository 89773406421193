import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-grid',
  templateUrl: './card-grid.component.html',
  styleUrls: ['./card-grid.component.scss']
})
export class CardGridComponent implements OnInit {
  year = new Date().getFullYear();

  constructor() {

  }

  ngOnInit() {
  }

  openGrid() {
  }
}
