import { Component, OnInit, HostListener, OnDestroy, ViewChild, AfterViewInit, ElementRef, NgZone, OnChanges } from '@angular/core';
import { translateYAnimation } from '../../animations/animations';
import { CatalogResponsiveBreakpoints, CatalogSettings, CatalogGoogleAnalyticsCategories, CatalogGoogleAnalyticsActions } from 'src/app/helper/catalog';
import { SocketService } from 'src/app/core/services/socket/socket.service';
import { Subscription } from 'rxjs';
import { Program } from 'src/app/models/program';
import { GridService } from 'src/app/core/services/grid/grid.service';
import { SettingsService } from 'src/app/core/services/settings/settings.service';
import { PlayerService } from 'src/app/core/services/player/player.service';
import { ProgramService } from 'src/app/core/services/program/program.service';
import { getLocalData } from 'src/app/helper/storage';
import { isNullOrEmptyOrUndefined } from 'src/app/helper/utils';
import { PushNotificationService, PushNotificationOptions } from 'ngx-push-notifications';
import { IconNotification } from 'src/app/helper/config';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { GoogleAnalyticsService } from 'src/app/core/services/google-analytics/google-analytics.service';

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss'],
  animations: [
    translateYAnimation
  ]
})
export class PlayerComponent implements OnInit, AfterViewInit, OnDestroy {
  show = true;
  playerStatus = 'down';
  openedMenu = false;
  socketSubscription: Subscription;
  playerSubscription: Subscription;
  program: Program;
  playerUrl: SafeResourceUrl;
  streamingUrl: SafeResourceUrl;
  isPlaying = false;
  firstLoad = true;
  volume = 50;

  @ViewChild('audioElement', { static: true }) audioElement: ElementRef;
  private audio: HTMLMediaElement;

  constructor(
    private ngZone: NgZone,
    private socketService: SocketService,
    private gridService: GridService,
    private settingsService: SettingsService,
    private playerService: PlayerService,
    private programService: ProgramService,
    private pushNotificationService: PushNotificationService,
    private googleAnalyticsService: GoogleAnalyticsService,
    public sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.getCurrentProgram();
    this.subscribeToSocket();
    this.subscribeToPlayer();
    this.getPlayer();
  }

  getCurrentProgram() {
    this.gridService.getNow().subscribe( response => {
      if (response.data['rows'].length) {
        this.program = new Program(response.data['rows'][0].program);
        this.emitAudienceEvent();
      }
    });
  }

  subscribeToSocket() {
    this.socketSubscription = this.socketService.getProgram().subscribe( response => {
      this.program = new Program(response.program);
      this.emitAudienceEvent();
      this.programService.emitCurrentProgram(this.program);
      const favourites = getLocalData('m90-web-favourites');
      if (!isNullOrEmptyOrUndefined(favourites) && favourites.includes(this.program.id)) {
        const isGranted = this.pushNotificationService.isPermissionGranted;
        if (isGranted) {
          const options = new PushNotificationOptions();
          options.icon = IconNotification;
          options.body = `${this.program.name} está por comenzar`;
          this.pushNotificationService.create('No te lo pierdas', options).subscribe( push => {
            if (push.event.type === 'show') {
                setTimeout(() => {
                    push.notification.close();
                }, 3000);
            }
            if (push.event.type === 'click') {
                push.notification.close();
            }
          });
        }
      }
    });
  }

  emitAudienceEvent() {
    // Emit audience event
    this.googleAnalyticsService.emitEvent(CatalogGoogleAnalyticsCategories.radioAudience, CatalogGoogleAnalyticsActions.listen, this.program.slug);
  }

  subscribeToPlayer() {
    this.playerSubscription = this.playerService.isPlaying$.subscribe( isPlaying => {
      this.getCurrentProgram();
      if (!this.firstLoad) {
        this.isPlaying = isPlaying;
        this.toggleAudio(isPlaying);
      } else {
        this.firstLoad = false;
      }
    });
  }

  togglePlayer(open: boolean) {
    this.playerStatus = (open) ? 'up' : 'down';
  }

  toggleAudio(status: boolean) {
    if (status) {
      this.audio.load();
      this.audio.play();
    } else {
      this.streamingUrl = undefined;
      this.audio.load();
      this.audio.pause();
      this.streamingUrl = this.playerUrl;
    }
  }

  loadAudio() {
    this.ngZone.runOutsideAngular( () => {
      setTimeout(() => {
        this.audio.load();
      });
    });
  }

  getPlayer() {
    const filters = {
      code: CatalogSettings.streaming
    };
    this.settingsService.get(null, null, filters).subscribe( response => {
      this.playerUrl = this.sanitizer.bypassSecurityTrustResourceUrl(response.data['rows'][0]['value']);
      this.streamingUrl = this.playerUrl;
    });
  }

  changeStatusPlayer(status) {
    this.playerService.emitPlayerStatus(status);
  }

  volumeDown() {
    const volume = this.volume - 10;
    this.volume = (volume > 0) ? volume : 0;
    this.audio.volume = this.volume / 100;
  }

  volumeUp() {
    const volume = this.volume + 10;
    this.volume = (volume < 100) ? volume : 100;
    this.audio.volume = this.volume / 100;
  }

  ngAfterViewInit() {
    this.audio = this.audioElement.nativeElement;
  }

  ngOnDestroy() {
    if (this.playerSubscription) { this.playerSubscription.unsubscribe(); }
    if (this.socketSubscription) { this.socketSubscription.unsubscribe(); }
    this.socketService.close();
  }

  @HostListener('window:scroll', [])
  onScroll() {
    if (window.innerWidth <= CatalogResponsiveBreakpoints.smart) {
      this.openedMenu = (window.pageYOffset >= CatalogResponsiveBreakpoints.pageYOffsetMenu) ? true : false;
    } else {
      this.openedMenu = false;
    }
  }
}
