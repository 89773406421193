import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { Observable } from 'rxjs';
import { BaseResponse } from 'src/app/models/base-response';
import { generateParamsForRoutes } from 'src/app/helper/utils';
import { BaseEntity } from 'src/app/models/base-entity';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  protected baseUrl: string;

  constructor(protected httpService: HttpService) { }

  get<T extends BaseEntity<T>>(page: number = 0, pageSize: number = 0, filters: any = []): Observable<BaseResponse<T>> {
    const url = this.baseUrl;
    const params = generateParamsForRoutes(filters, page, pageSize);
    return this.httpService.get<T>(url, params, true);
  }

  getOne<T extends BaseEntity<T>>(id: number | string, filters: any = [], showLoader = true): Observable<BaseResponse<T>> {
    const params = generateParamsForRoutes(filters);
    return this.httpService.getOne(this.baseUrl, id, showLoader, params);
  }

  edit<T extends BaseEntity<T>>(obj: any, id: number): Observable<BaseResponse<T>> {
    const url = `${this.baseUrl}/${id}`;
    return this.httpService.put(url, obj, true);
  }

  add<T extends BaseEntity<T>>(obj: any): Observable<BaseResponse<T>> {
    const url = this.baseUrl;
    return this.httpService.post(url, obj, true);
  }

  delete<T extends BaseEntity<T>>(id: number): Observable<BaseResponse<T>> {
    const url = `${this.baseUrl}/${id}`;
    return this.httpService.delete(url, true);
  }
}
