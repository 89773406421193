import { Component, OnInit, Input } from '@angular/core';
import { News } from 'src/app/models/news';
import { NguCarouselConfig } from '@ngu/carousel';

@Component({
  selector: 'app-list-news',
  templateUrl: './list-news.component.html',
  styleUrls: ['./list-news.component.scss']
})
export class ListNewsComponent implements OnInit {
  carouselConfig: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
    interval: {
      timing: 4000,
      initialDelay: 1000
    },
    loop: true,
    touch: true,
    velocity: 0,
    point: {
      visible: true,
      hideOnSingleSlide: true
    }
  };

  @Input() news: News[];

  constructor() { }

  ngOnInit() {
  }

}
