import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Settings } from 'src/app/models/settings';
import { BaseResponse } from 'src/app/models/base-response';
import { SettingsService } from 'src/app/core/services/settings/settings.service';
import { objectKeysToArray } from 'src/app/helper/utils';
import { CatalogCommunicationSettings } from 'src/app/helper/catalog';

@Component({
  selector: 'app-commercial',
  templateUrl: './commercial.component.html',
  styleUrls: ['./commercial.component.scss']
})
export class CommercialComponent implements OnInit {
  settings: string[] = [];

  constructor(
    private location: Location,
    private settingService: SettingsService
  ) { }

  ngOnInit() {
    this.getSettings();
  }

  getSettings() {
    const filters = {
      order: 'id,ASC'
    };
    this.settingService.get(null, null, filters).subscribe( response => {
      const settings = new BaseResponse<Settings>(response, Settings);
      CatalogCommunicationSettings.forEach( code => {
        const setting = settings.data['rows'].find( item => item.code === code);
        if (setting) {
          this.settings.push(setting.value);
        }
      });
    });
  }

  goBack() {
    this.location.back();
  }
}
