import { Component, OnInit, Input } from '@angular/core';
import { changeBackground } from '../../animations/animations';

@Component({
  selector: 'app-background',
  templateUrl: './background.component.html',
  styleUrls: ['./background.component.scss'],
  animations: [
    changeBackground
  ]
})
export class BackgroundComponent implements OnInit {
  @Input() color = 'green';

  constructor() { }

  ngOnInit() {
  }

}
