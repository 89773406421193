import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-web-view-layout',
  templateUrl: './web-view-layout.component.html',
  styleUrls: ['./web-view-layout.component.scss']
})
export class WebViewLayoutComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit() {
  }

}
