import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Routes
import { PUBLIC_ROUTES } from './shared/routes/public.routes';
import { WEBVIEW_ROUTES } from './shared/routes/web-view.routes';

// Layouts
import { PublicLayoutComponent } from './core/components/layouts/public/public.component';
import { WebViewLayoutComponent } from './core/components/layouts/web-view-layout/web-view-layout.component';

const routes: Routes = [
  { path: '', component: PublicLayoutComponent, children: PUBLIC_ROUTES },
  { path: 'web-view', component: WebViewLayoutComponent, children: WEBVIEW_ROUTES },
  { path: '**', redirectTo: '/error/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})

export class AppRoutingModule {

}
