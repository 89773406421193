import { Metadata } from './metadata';
import { BaseEntity } from './base-entity';

export class BaseResponseData<T extends BaseEntity<T>> {
    rows?: T[];
    metadata?: Metadata;
    token?: string;

    constructor(baseResponseData, modelType) {
        this.rows = modelType.parseMultiple(baseResponseData.rows);
        if (baseResponseData.metadata) { this.metadata = new Metadata(baseResponseData.metadata); }
        if (baseResponseData.token) { this.token = baseResponseData.token; }
    }
}

export class BaseResponse<T extends BaseEntity<T>> {
    status?: boolean;
    data: T | BaseResponseData<T>;
    message?: string;

    constructor(baseResponse, type: new() => T) {
        if (baseResponse.status) { this.status = baseResponse.status; }
        const modelType: T = new type();
        if (!baseResponse.data.rows) {
            // In some case, the object contains rows (T[]), if not we add a single object model
            Object.keys(baseResponse.data).forEach( key => {
                if (baseResponse.data[key].rows) {
                    this.data = new BaseResponseData(baseResponse.data[key], modelType);
                    return;
                }
            });
            if (!this.data) { this.data = modelType.parse(baseResponse.data); }
        } else {
            this.data = new BaseResponseData(baseResponse.data, modelType);
        }
        if (baseResponse.message) { this.message = baseResponse.message; }
    }
}
