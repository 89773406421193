import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Program } from 'src/app/models/program';
import { Subscription } from 'rxjs';
import { PlayerService } from 'src/app/core/services/player/player.service';

@Component({
  selector: 'app-card-program',
  templateUrl: './card-program.component.html',
  styleUrls: ['./card-program.component.scss']
})
export class CardProgramComponent implements OnInit, OnDestroy {
  playerSubscription: Subscription;
  isPlaying = false;

  @Input() program: Program;
  @Input() bgWithGrayScale = true;

  constructor(private playerService: PlayerService) { }

  ngOnInit() {
    this.subscribeToPlayer();
  }

  subscribeToPlayer() {
    this.playerSubscription = this.playerService.isPlaying$.subscribe( isPlaying => {
      this.isPlaying = isPlaying;
    });
  }

  changeStatusPlayer(status) {
    this.playerService.emitPlayerStatus(status);
  }

  ngOnDestroy() {
    if (this.playerSubscription) { this.playerSubscription.unsubscribe(); }
  }

}
