import { Component, OnInit, Input, Output, EventEmitter, NgZone } from '@angular/core';
import { animateButton } from '../../animations/animations';

@Component({
  selector: 'app-button-play',
  templateUrl: './button-play.component.html',
  styleUrls: ['./button-play.component.scss'],
  animations: [
    animateButton
  ]
})
export class ButtonPlayComponent implements OnInit {
  animateButton = 'false';

  @Input() size = 42;
  @Input() active = false;
  @Input() class: string;
  @Input() changeIconOnClick = true;

  @Output() clickPlay = new EventEmitter();

  constructor(private ngZone: NgZone) { }

  ngOnInit() {
  }

  clickPlayButton() {
    if (this.changeIconOnClick) {
      this.active = !this.active;
      // Only animate if the icon change and change to active status
      if (this.active) {
        this.animate();
      }
    }
    this.clickPlay.emit(this.active);
  }

  animate() {
    this.animateButton = 'true';
    this.ngZone.runOutsideAngular( _ => {
      // tslint:disable-next-line:no-shadowed-variable
      setTimeout( _ => {
        this.animateButton = 'false';
      }, 3000);
    });
  }
}
