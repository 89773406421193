import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { NguCarouselConfig } from '@ngu/carousel';
import { ProgramMember } from 'src/app/models/program-member';
import { Operator } from 'src/app/models/operator';

@Component({
  selector: 'app-list-staff',
  templateUrl: './list-staff.component.html',
  styleUrls: ['./list-staff.component.scss']
})
export class ListStaffComponent implements OnInit, OnChanges {
  carouselConfig: NguCarouselConfig = {
    grid: { xs: 2, sm: 2, md: 2, lg: 2, all: 0 },
    interval: { timing: 4000, initialDelay: 1000 },
    loop: false,
    touch: true,
    velocity: 0,
    point: {
      visible: true,
      hideOnSingleSlide: true
    }
  };

  @Input() members: ProgramMember[] = [];
  @Input() staff: Operator[] = [];

  constructor() { }

  ngOnInit() {
    this.getStaffByMembers();
  }

  ngOnChanges() {
    this.getStaffByMembers();
  }

  getStaffByMembers() {
    if (this.members.length) {
      const staff = [];
      this.members.forEach(member => {
        staff.push(member.operator);
      });
      this.staff = staff;
    }
  }
}
