import { BaseEntity } from './base-entity';

export class SocialMedia extends BaseEntity<SocialMedia> {
  facebook?: string;
  instagram?: string;
  playlist?: string;
  podcast?: string;
  soundcloud?: string;
  spotify?: string;
  twitter?: string;
  whatsapp?: string;
  whatsappText?: string;
  youtube?: string;

  constructor(socialMedia?) {
    super(socialMedia);
  }

  parse(e: any): SocialMedia {
    const socialMedia = new SocialMedia(e);
    return socialMedia;
  }

  populate(socialMedia) {
    this.facebook = socialMedia.facebook;
    this.instagram = socialMedia.instagram;
    this.playlist = socialMedia.playlist;
    this.podcast = socialMedia.podcast;
    this.soundcloud = socialMedia.soundcloud;
    this.spotify = socialMedia.spotify;
    this.twitter = socialMedia.twitter;
    this.whatsapp = socialMedia.whatsapp;
    this.whatsappText = socialMedia.whatsappText;
    this.youtube = socialMedia.youtube;
  }
}
