import { trigger, style, animate, transition, state, keyframes } from '@angular/animations';

export const enterAnimation = trigger(
  'enterAnimation', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate('10ms', style({ opacity: 1 }))
    ]),
    transition(':leave', [
      animate('500ms', style({ opacity: 0 }))
    ])
  ]
);


export const showHideAnimation = trigger('showHideAnimation', [
  state('show', style({
      opacity: 1
  })),
  state('hide', style({
      opacity: 0,
      display: 'none'
  })),
  transition('show => hide', animate('{{time}} ease-out'), { params: { time: '1500ms' } }),
  transition('hide => show', animate('{{time}} ease-in'), { params: { time: '1500ms' } })
]);

export const translateYAnimation = trigger('translateYAnimation', [
    state('up', style({
      opacity: '{{opacity}}',
      transform: 'translateY(-100%)'
    }),
    { params: { opacity: 1 } }),
    state('down', style({
      opacity: '{{opacity}}',
      transform: 'translateY(0%)'
    }),
    { params: { opacity: 0 } }),
    transition('up => down', animate('550ms ease-out')),
    transition('down => up', animate('550ms ease-in'))
]);

export const changeBackground = trigger('changeBackground', [
    state('green', style({
      opacity: '{{opacity}}'
    }),
    { params: { opacity: 0 } }),
    state('orange', style({
      opacity: '{{opacity}}'
    }),
    { params: { opacity: 1 } }),
    transition('green => orange', animate('550ms ease-out')),
    transition('orange => green', animate('550ms ease-in'))
]);

export const animateButton = trigger('animateButton', [
  transition('false => true', animate('3000ms', keyframes([
    style({ opacity: 0.5 }),
    style({ opacity: 1 }),
    style({ opacity: 0.5 }),
    style({ opacity: 1 })
  ])))
]);
