import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PlayerService {

  private isPlaying = new BehaviorSubject<boolean>(false);
  isPlaying$ = this.isPlaying.asObservable();

  constructor() {}

  emitPlayerStatus(isPlaying: boolean) {
    this.isPlaying.next(isPlaying);
  }
}
