// Modules
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NguCarouselModule } from '@ngu/carousel';
import { RouterModule } from '@angular/router';
// Services
import { PushNotificationService } from 'ngx-push-notifications';
// Components
import { CardProgramComponent } from './components/card-program/card-program.component';
import { ButtonPlayComponent } from './components/button-play/button-play.component';
import { ButtonOptionsComponent } from './components/button-options/button-options.component';
import { ListNewsComponent } from './components/list-news/list-news.component';
import { CardNewsComponent } from './components/card-news/card-news.component';
import { CardStaffComponent } from './components/card-staff/card-staff.component';
import { ListStaffComponent } from './components/list-staff/list-staff.component';
import { CardDescriptionComponent } from './components/card-description/card-description.component';
import { CardSpotifyComponent } from './components/card-spotify/card-spotify.component';
import { SocialMediaComponent } from './components/social-media/social-media.component';
import { PlayerComponent } from './components/player/player.component';
import { MobileMenuComponent } from './components/mobile-menu/mobile-menu.component';
import { GoBackComponent } from './components/go-back/go-back.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CardGridComponent } from './components/card-grid/card-grid.component';
import { FilterPipe } from './pipes/filter.pipe';
import { BackgroundComponent } from './components/background/background.component';
import { ListProgramComponent } from './components/list-program/list-program.component';
import { CardCommercialComponent } from './components/card-commercial/card-commercial.component';
import { CommercialComponent } from './components/commercial/commercial.component';
import { CardSoundcloudComponent } from './components/card-soundcloud/card-soundcloud.component';

const importExport = [
  NgbModule,
  CommonModule,
  HttpClientModule,
  NguCarouselModule,
  RouterModule
];

const declarationsExports = [
  FilterPipe,
  CardProgramComponent,
  ButtonPlayComponent,
  ButtonOptionsComponent,
  ListNewsComponent,
  CardNewsComponent,
  CardStaffComponent,
  ListStaffComponent,
  ListProgramComponent,
  CardDescriptionComponent,
  CardSpotifyComponent,
  CardGridComponent,
  SocialMediaComponent,
  PlayerComponent,
  MobileMenuComponent,
  GoBackComponent,
  BackgroundComponent,
  CardCommercialComponent,
  CommercialComponent,
  CardSoundcloudComponent
];

@NgModule({
  declarations: [
    ...declarationsExports,
    GoBackComponent,
    BackgroundComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    ...importExport
  ],
  exports: [
    ...importExport,
    ...declarationsExports
  ],
  providers: [
    PushNotificationService
  ]
})
export class SharedModule { }
