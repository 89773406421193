import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { ApiService } from '../api/api.service';
import { EndpointsConstants } from 'src/app/helper/endpoints';

@Injectable({
  providedIn: 'root'
})
export class SettingsService extends ApiService {

  constructor(
    httpService: HttpService
  ) {
    super(httpService);
    this.baseUrl = EndpointsConstants.settings.base;
  }
}
