import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as io from 'socket.io-client';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SocketService {
  private socket;
  private socketUrl = environment.socket.baseUrl;

  constructor() {
    this.connect();
  }

  connect() {
    this.socket = io.connect(this.socketUrl, {
        query: `apikey=${environment.socket.key}`,
        redirect: false
    });
  }

  getProgram() {
    return Observable.create( observer => {
      this.socket.on('program_change', (response) => {
        observer.next(response);
      });
    });
  }

  close() {
    this.socket.close();
  }
}
