import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { GoogleAnalyticsService } from '../google-analytics/google-analytics.service';

@Injectable({
  providedIn: 'root'
})
export class RouteService {

  private previousUrl: string;
  private currentUrl: string;

  constructor(
    private router: Router,
    private googleAnalyticsService: GoogleAnalyticsService,
  ) {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Set previous and current route
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        // Set page
        this.googleAnalyticsService.emitPage(event.urlAfterRedirects);
      }
    });
  }

  getPreviousUrl() {
    return this.previousUrl;
  }
}
