import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-card-spotify',
  templateUrl: './card-spotify.component.html',
  styleUrls: ['./card-spotify.component.scss']
})
export class CardSpotifyComponent implements OnInit {
  @Input() id: string;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() urlType: string;

  constructor(private router: Router) { }

  ngOnInit() {
  }

  clickPlay() {
    this.router.navigate(['/spotify', 'play', this.urlType, this.id]);
  }
}
