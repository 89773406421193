import { ApiService } from '../api/api.service';
import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { EndpointsConstants } from 'src/app/helper/endpoints';
import { BehaviorSubject, Observable } from 'rxjs';
import { Program } from 'src/app/models/program';
import { BaseResponse } from 'src/app/models/base-response';
import { generateParamsForRoutes } from 'src/app/helper/utils';

@Injectable({
    providedIn: 'root'
})
export class ProgramService extends ApiService {

    private currentProgram = new BehaviorSubject<Program>(undefined);
    currentProgram$ = this.currentProgram.asObservable();

    private viewProgram = new BehaviorSubject<Program>(undefined);
    viewProgram$ = this.viewProgram.asObservable();

    constructor(httpService: HttpService) {
      super(httpService);
      this.baseUrl = EndpointsConstants.program.base;
    }

    getBySlug(slug: string, filters: any = [], showLoader = true): Observable<BaseResponse<Program>> {
      const params = generateParamsForRoutes(filters);
      const url = `${this.baseUrl}${EndpointsConstants.program.slug}`;
      return this.httpService.getOne(url, slug, showLoader, params);
    }

    emitCurrentProgram(currentProgram: Program) {
      this.currentProgram.next(currentProgram);
    }

    emitViewProgram(viewProgram: Program) {
      this.viewProgram.next(viewProgram);
    }
}
