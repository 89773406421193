import { Component, NgZone, OnDestroy, HostListener, OnInit } from '@angular/core';
import { RouteService } from './core/services/route/route.service';
import { GoogleAnalyticsService } from './core/services/google-analytics/google-analytics.service';
import { CatalogGoogleAnalyticsCategories, CatalogGoogleAnalyticsActions } from './helper/catalog';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  private emitEventInterval;

  // RouteService: Used to call Google Analytics set page event, and to set previous and current page
  constructor(
    private ngZone: NgZone,
    private routeService: RouteService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
    this.googleAnalyticsHearbeat();
  }

  ngOnInit() {
  }

  googleAnalyticsHearbeat() {
    this.ngZone.runOutsideAngular( _ => {
      this.emitEventInterval = setInterval(() => {
        this.googleAnalyticsService.emitEvent(CatalogGoogleAnalyticsCategories.heartbeat, CatalogGoogleAnalyticsActions.hearbeatTime);
      }, 10000);
    });
  }

  ngOnDestroy() {
    clearInterval(this.emitEventInterval);
  }

  @HostListener('window:beforeunload')
  unloadHandler() {
    clearInterval(this.emitEventInterval);
  }
}
