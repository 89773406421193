import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { ApiService } from '../api/api.service';
import { EndpointsConstants } from 'src/app/helper/endpoints';
import { BehaviorSubject, Observable } from 'rxjs';
import { Operator } from 'src/app/models/operator';
import { BaseResponse } from 'src/app/models/base-response';
import { Program } from 'src/app/models/program';
import { generateParamsForRoutes } from 'src/app/helper/utils';

@Injectable({
  providedIn: 'root'
})
export class OperatorService extends ApiService {

  private currentOperator = new BehaviorSubject<Operator>(undefined);
  currentOperator$ = this.currentOperator.asObservable(); e;

  constructor(httpService: HttpService) {
    super(httpService);
    this.baseUrl = EndpointsConstants.operator.base;
  }

  getBySlug(slug: string, filters: any = [], showLoader = true): Observable<BaseResponse<Program>> {
    const params = generateParamsForRoutes(filters);
    const url = `${this.baseUrl}${EndpointsConstants.program.slug}`;
    return this.httpService.getOne(url, slug, showLoader, params);
  }

  emitCurrentOperator(currentOperator: Operator) {
    this.currentOperator.next(currentOperator);
  }
}
