import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FavouriteService {

  private changeFavourite = new BehaviorSubject<boolean>(false);
  changeFavourite$ = this.changeFavourite.asObservable();

  constructor() {}

  emitChangeFavourite() {
    this.changeFavourite.next(true);
  }
}
