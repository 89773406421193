import { isUndefined } from 'util';

export interface IParser<T> {
    parse(e: any): T;
    parseMultiple(es: any[]): T[];
}

export abstract class BaseEntity<T> implements IParser<T> {

    constructor(e?: any) {
        if (!isUndefined(e)) { this.populate(e); }
    }

    public abstract populate(e: any);

    public abstract parse(e: any): T;

    public parseMultiple(es: any[]): T[] {
        const u = [];
        for (const e of es) {
            u.push(this.parse(e));
        }
        return u;
    }
}

