import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';

// tslint:disable-next-line:ban-types
declare let ga: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor(private analytics: AngularFireAnalytics) {}

  emitPage(url: string) {
    ga('set', 'page', url);
    ga('send', 'pageview');
  }

  emitEvent(eventCategory: string, eventAction: string, eventLabel: string = null, eventValue: number = null) {
    // Analytics
    ga('send', 'event', {
      eventCategory,
      eventAction,
      eventLabel,
      eventValue
    });
    // Firebase
    this.analytics.logEvent(eventCategory, {
      eventAction,
      eventLabel,
      eventValue
    });
  }
}
