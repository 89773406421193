import { BaseEntity } from './base-entity';
import { Operator } from './operator';
import { SocialMedia } from './social-media';
import { ProgramMember } from './program-member';

export class Program extends BaseEntity<Program> {
  id?: number;
  slug: string;
  active: boolean;
  name: string;
  subtitle: string;
  avatar: string;
  time: string;
  description: string;
  socialMedia?: SocialMedia;
  programMembers: ProgramMember[];
  operatorsStaff: Operator[];
  operatorsDirector: Operator[];

  constructor(program?) {
    super(program);
  }

  parse(e: any): Program {
    const program = new Program(e);
    return program;
  }

  populate(program) {
    if (program.id) { this.id = program.id; }
    this.slug = program.slug;
    this.active = (program.active) ? true : false;
    this.name = program.name;
    this.subtitle = program.subtitle;
    this.description = program.description;
    this.operatorsStaff = program.operatorsStaff;
    this.operatorsDirector = program.operatorsStaff;
    if (program.programMembers) { this.programMembers = this.setMembers(program.programMembers); }
    this.avatar = program.avatar;
    this.time = program.time;
    if (program.socialMedia) { this.socialMedia = new SocialMedia(program.socialMedia); }
  }

  setMembers?(members) {
    const temp = [];
    members.forEach(member => {
      temp.push(new ProgramMember(member));
    });
    return temp;
  }
}
