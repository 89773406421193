import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { HttpService } from '../http/http.service';
import { EndpointsConstants } from 'src/app/helper/endpoints';

@Injectable({
    providedIn: 'root'
})
export class SearchService extends ApiService {

    constructor(httpService: HttpService) {
        super(httpService);
        this.baseUrl = EndpointsConstants.search.base;
    }
}
