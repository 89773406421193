export const EndpointsConstants = {
  requestLimit: 20,
  shortRequestLimit: 12,
  grid: {
    base: '/grid',
    now: '/now'
  },
  settings: {
    base: '/settings'
  },
  news: {
    base: '/news'
  },
  program: {
    base: '/program',
    slug: '/slug'
  },
  operator: {
    base: '/operator'
  },
  podcast: {
    base: '/podcast'
  },
  search: {
    base: '/search'
  }
};
