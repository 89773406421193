import { Component, OnInit, HostListener, ElementRef, ViewChild } from '@angular/core';
import { CatalogResponsiveBreakpoints } from 'src/app/helper/catalog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SearchService } from 'src/app/core/services/search/search.service';
import { NguCarouselConfig, NguCarousel } from '@ngu/carousel';
import { translateYAnimation } from '../../animations/animations';
import { Program } from 'src/app/models/program';
import { Operator } from 'src/app/models/operator';
import { News } from 'src/app/models/news';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
  animations: [
    translateYAnimation
  ]
})
export class MobileMenuComponent implements OnInit {
  carouselConfig: NguCarouselConfig = {
    grid: { xs: 2, sm: 2, md: 2, lg: 2, all: 0 },
    interval: { timing: 4000, initialDelay: 1000 },
    loop: false,
    touch: true,
    velocity: 0,
    point: {
      visible: true,
      hideOnSingleSlide: true
    }
  };
  searchStatus = 'up';
  showMenu = false;
  activeSearch = false;
  searchForm: FormGroup;
  programs: Program[] = [];
  staff: Operator[] = [];
  news: News[] = [];
  isSearchOpen: boolean;

  @ViewChild('inputElement', { static: false }) inputElement: ElementRef;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private searchService: SearchService
  ) {
    this.searchForm = this.fb.group({
      query: ['']
    });
  }

  ngOnInit() {
    this.subscribeToRouterEvents();
  }

  closeSearch() {
    this.activeSearch = false;
    this.searchForm.get('query').setValue('');
    this.searchStatus = 'up';
    this.news = [];
    this.staff = [];
    this.programs = [];
    this.isSearchOpen = false;
  }

  openSearch() {
    this.activeSearch = true;
    this.isSearchOpen = true;
    setTimeout( _ => {
      this.inputElement.nativeElement.focus();
    }, 100);
  }

  onSubmit() {
    this.searchStatus = 'down';
    const filters = {
      query: this.searchForm.get('query').value,
      limit: 10
    };
    this.searchService.get(null, null, filters).subscribe(response => {
      this.news = response.data['news'];
      this.staff = response.data['operator'];
      this.programs = response.data['program'];
    });
  }

  subscribeToRouterEvents() {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.closeSearch();
      }
    });
  }

  @HostListener('window:scroll', [])
  onScroll() {
    if (window.innerWidth <= CatalogResponsiveBreakpoints.smart) {
      this.showMenu = (this.isSearchOpen || window.pageYOffset >= CatalogResponsiveBreakpoints.pageYOffsetMenu) ? true : false;
    } else {
      this.showMenu = false;
    }
  }

}
