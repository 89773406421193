import { BaseEntity } from './base-entity';

export class Settings extends BaseEntity<Settings> {
  id?: number;
  name: string;
  code: string;
  value: string;
  type: string;

  constructor(setting?) {
    super(setting);
  }

  parse(e: any): Settings {
    const setting = new Settings(e);
    return setting;
  }

  populate(settings) {
    if (settings.id) { this.id = settings.id; }
    this.name = settings.name;
    this.code = settings.code;
    this.value = settings.value;
    this.type = settings.type;
  }
}
